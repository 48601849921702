import React, { useEffect, useState } from "react";
import "./Support.css";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import { API } from "../../API/Api";
import { toast } from "react-toastify";
import { UpdateAuth } from "../../Redux/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import DOMPurify from "dompurify";
import { useNavigate } from "react-router-dom";
import Table from "../Table/Table";
import TableButtons from "../Pagination/TableButtons";

const Support = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [ReportApi, setReportApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(50);
  const [query, setQuery] = useState("");
  const [queryText, setText] = useState("");
  const dispatch = useDispatch();
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const handleLinkClick = () => {
    // Close the sidebar when a link is clicked
    setShowSidebar(false);
  };

  const user = useSelector((state) => state.Auth.userId);
  const token = useSelector((state) => state.Auth.jwt_token);

  const Submit_API = async (event) => {
    event.preventDefault();
    try {
      let responce = await API.post(
        "HelpDesk",
        {
          uid: user,
          query: query,
          querTypeText: queryText,
        },
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      responce = responce;
      // console.log("Submit-->", responce);
      Report_API();
      setQuery("");
      setText("");
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(
          UpdateAuth({
            isAuth: false,
            userId: null,
            jwt_token: null,
          })
        );
      }
    }
  };

  const Report_API = async () => {
    try {
      let responce = await API.post(
        "GetHelpdeskuser",
        {
          uid: user,
        },
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      responce = responce.data.data[0];
      console.log("res-->", responce);
      setReportApi([]);

      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: index + 1,
          status: item.status,
          edate: item.edate[0],
          subject: item.subject,
          reply_message: item.reply_message,
          ticket_id: item.ticket_id,
          query: item.query
        });
      });

      setReportApi(arr);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(
          UpdateAuth({
            isAuth: false,
            userId: null,
            jwt_token: null,
          })
        );
      }
    }
  };

  useEffect(() => {
    Report_API();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = ReportApi.slice(indexOfFirstPage, indexOfLastPost);

  var [reportData, set_reportData] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "Status", accessor: "status" },
      { Header: "Date", accessor: "edate" },
      { Header: "Query Subject", accessor: "subject" },
      { Header: "query", accessor: "query" },
      { Header: "Admin Reply", accessor: "reply_message" },
      { Header: "Ticket Id", accessor: "ticket_id" },
    ],
  });

  function handleQuery(e) {
    const { value } = e.target;
    setQuery(DOMPurify.sanitize(value));
  }

  function handleQueryText(e) {
    const { value } = e.target;
    setText(DOMPurify.sanitize(value));
  }
  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} showSidebar={showSidebar} />

      <Sidebar handleLinkClick={handleLinkClick} showSidebar={showSidebar} />
      <Footer />

      <div className="col-lg-10 com-h">
        <div className="row">
          <div className="col-12 p-3">
            <div className="bet-h ">
              <h4 className="p-2 ms-2">Help Desk</h4>
              <form onSubmit={Submit_API} id="queryForm">
                <div className="mb-3">
                  <label
                    htmlFor="Subject"
                    className="form-label label fs-6"
                  >
                    Subject
                  </label>
                  <input
                    type="text"
                    className="form-control shadow-none w-100 bg-transparent border-theme1 text-white placeholder1"
                    id="Subject"
                    placeholder="Subject"
                    onChange={handleQuery}
                    value={query}
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="query"
                    className="form-label label fs-6"
                  >
                    Your Query
                  </label>
                  <textarea
                    className="form-control shadow-none bg-transparent border-theme1 text-white"
                    id="query"
                    rows={3}
                    value={queryText}
                    onChange={handleQueryText}
                  />
                </div>
                <div className="submitbtn text-center my-5">
                  <button
                    className="btn btn-theme1 px-4 py-2 text-white fw-bold"
                    type="submit"
                  >
                    Submit Query
                  </button>
                </div>
              </form>
              <div className="container">
                <Table
                  data={[...currentPost]}
                  columns={reportData.cols}
                />

                <TableButtons
                  indexOfFirstPage={indexOfFirstPage}
                  indexOfLastPost={indexOfLastPost}
                  setcurrentPage={setcurrentPage}
                  currentPage={currentPage}
                  totalData={ReportApi.length}
                  listPerpage={listPerpage}
                  handleRowsPerPageChange={setlistPerpage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Support;