import React from "react";

export default function Features() {
  return (
    <div id="feature">
      <div className="container-fluid py-lg-5 py-md-4 mb-5 mb-sm-4 mb-md-0 new_Assets_Section">
        <div className="container">
          {/* <div className="row">
            <div className="col-12">
              <div className="sectionHeading text-center">
                New <span className="text-theme1">Decade,</span> New <br />
                Assets
              </div>
              <div className="subHeadinsection text-center">
                Now it's time to come up with great slogan to tie all the pieces
                together. <br />
                And not just a slogan
              </div>
            </div>
          </div> */}
          <div className="row g-3">
            <div className="col-lg-6">
              <div className="card">
                <div className="card-body maib">
                  <div className="cardimage">
                    <center>
                    <img
                      className="miss"
                      src="./assets/image/home/4.jpg"
                      alt=""
                    />
                    </center>
                  </div>
                  <div className="card-heading">Transparency</div>
                  <p>
                  Moscow Spin ensures transparency in gameplay, providing users with fair and unbiased color prediction experiences.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card">
                <div className="card-body maib">
                  <div className="cardimage">
                    <center>
                    <img
                      className="miss"
                      src="./assets/image/home/5.jpg"
                      alt="iage"
                    />
                    </center>
                  </div>
                  <div className="card-heading">Exciting Rewards</div>
                  <p>
                   Players choose Moscow Spin for the opportunity to win exciting rewards through thrilling color prediction games.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card">
                <div className="card-body maib">
                  <div className="cardimage">
                    <center>
                    <img
                      className="miss"
                      src="./assets/image/home/6.jpg"
                      alt="imag"
                    />
                    </center>
                  </div>
                  <div className="card-heading">User-Friendly Interface</div>
                  <p>
                   Moscow Spin offers a user-friendly interface, making it easy and enjoyable for players to participate in color prediction activities.
                  </p>
                </div>
              </div>
            </div>
           
            <div className="col-lg-6">
              <div className="card">
                <div className="card-body maib">
                  <div className="cardimage">
                    <center>
                    <img
                      className="miss"
                      src="./assets/image/home/7.jpg"
                      alt="imag"
                     
                    />
                    </center>
                  </div>
                  <div className="card-heading">Community Engagement</div>
                  <p>
                     Moscow Spin fosters a vibrant and engaging community of players, enhancing the overall gaming experience with competitions, leaderboards, and social interaction.
                  </p>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  );
}
