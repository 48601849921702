import React, { useRef, useState, useEffect } from "react";
import "./Deposit.css";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../API/Api";
import { toast } from "react-toastify";
import { UpdateAuth } from "../../Redux/AuthSlice";
import DOMPurify from "dompurify";
import { useNavigate } from "react-router-dom";
import {
  ContractAddress_Main,
  ABI_Main,
  ContractAddress_USDT,
  ABI_USDT,
} from "../../utils/ContractAddress";
import { loadWeb3 } from "../../web3apis/apiBnb";

const Deposit = () => {
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const { userId, jwt_token } = useSelector((state) => state.Auth);

  const [showSidebar, setShowSidebar] = useState(false);
  const [walletAddress, setwalletAddress] = useState("");
  const [USDAmount, setUSDAmount] = useState(0);
  const [RupeeAmount, setRupeeAmount] = useState(0);
  const [txn, setTxn] = useState("");
  const navigate = useNavigate();
  let [connected, setconnected] = useState("Wallet is locked");
  let [USDTBal, setUSDTBal] = useState(0);

  const UserInfo_API = async () => {
    try {
      const bankres = await API?.get(`userBankDetails?uid=${userId}`, {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      });

      setwalletAddress(bankres.data.data[0][0].accountaddress);
    } catch (e) {
      console.log("Error While calling userinfomyReferrals API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  const walletConnected = async () => {
    try {
      let acc = await loadWeb3();

      if (acc == "No Wallet") {
        setconnected("Wallet is locked");
      } else if (acc == "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSC Network");
        setconnected("Wrong Network");
      } else {
        setconnected("Wallet is Connected");
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const USDTBalance = async () => {
    try {
      let acc = await loadWeb3();
      let contract = await new window.web3.eth.Contract(
        ABI_USDT,
        ContractAddress_USDT
      );
      let result = await contract.methods.balanceOf(acc.toString()).call();
      console.log("result-->", result);
      result = parseFloat(result) / 10 ** 18;
      console.log("result-->", parseFloat(result).toFixed(6));
      setUSDTBal(parseFloat(result).toFixed(6));
    } catch (error) {
      console.log(error);
    }
  };

  const CalculateValueU2R = async (val) => {
    let value = DOMPurify.sanitize(val);
    value = value.replace(/[^0-9]/gi, "").substring(0, 8);
    const tvalue = parseFloat(value);
    setUSDAmount(tvalue);
    setRupeeAmount(parseFloat(tvalue) * parseFloat(90.0));
  };

  const CalculateValueR2U = async (val) => {
    let value = DOMPurify.sanitize(val);
    value = value.replace(/[^0-9]/gi, "").substring(0, 8);
    const tvalue = parseFloat(value);
    setRupeeAmount(tvalue);
    setUSDAmount(parseFloat(tvalue) / parseFloat(90.0));
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const handleLinkClick = () => {
    // Close the sidebar when a link is clicked
    setShowSidebar(false);
  };

  const buyToken = async () => {
    let acc = await loadWeb3();
    if ((walletAddress === "") || (walletAddress === undefined)|| (walletAddress === null)) {
      toast.error("Update your wallet address In Profile!!!");
      return;
    }
    if (acc == "No Wallet") {
      toast.error("No Wallet");
    } else if (acc == "Wrong Network") {
      toast.error("Wrong Network Please Connect BSC Scan Network");
    } else {
      if (walletAddress.toUpperCase() == acc.toUpperCase()) {
        if (USDAmount != 0) {
          if (USDTBal < USDAmount) {
            toast.error("Insufficient USDT Balance");
          } else if (parseFloat(USDAmount) < 10) {
            toast.error("you can deposite minimum 10 USDT");
          } else {
            let amountValue = (USDAmount * 10 ** 18).toString();

            const gasPrice = await window.web3.eth.getGasPrice();
            const approveBlock = window.web3.eth.getBlock("latest");

            try {
              let contract_Main = await new window.web3.eth.Contract(
                ABI_Main,
                ContractAddress_Main
              );
              let contract_USDT = await new window.web3.eth.Contract(
                ABI_USDT,
                ContractAddress_USDT
              );

              let approveCall = await contract_USDT.methods
                .approve(ContractAddress_Main, amountValue)
                .send({
                  from: acc,
                  gasLimit: approveBlock.gasLimit,
                  gasPrice: await window.web3.eth.getGasPrice(),
                });
              toast.success("Approved Succesful");
              let sellCall = await contract_Main.methods
                .deposit(amountValue)
                .send({
                  from: acc,
                  gasLimit: approveBlock.gasLimit,
                  gasPrice: await window.web3.eth.getGasPrice(),
                });

              let res = await API.post(
                `addDepositeFund`,
                {
                  uid: userId,
                  walletAddress: acc,
                  amount: USDAmount,
                  txn: sellCall.transactionHash,
                },
                {
                  headers: {
                    Authorization: `${jwt_token}`,
                    "Content-Type": "application/json",
                  },
                }
              );

              toast.success("Transaction Succesful");
              if (res.data.success == true) {
                toast.success("Deposit Successful");
                window.location.href = "/Dashboard";
              }
            } catch (err) {
              console.log("error while calling fuction sell", err);
            }
          }
        } else {
          toast.error("Please Enter Amout First");
        }
      } else {
        toast.error("Invalid Wallet address");
      }
    }
  };

  useEffect(() => {
    walletConnected();
    USDTBalance();
    UserInfo_API();
  }, []);

  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} showSidebar={showSidebar} />

      <Sidebar handleLinkClick={handleLinkClick} showSidebar={showSidebar} />
      <Footer />
      <div className="col-lg-10">
        <div className="row">
          <div className="col-12 px-2 p-1">
            <div className="funds-transfer-main-div ">
              <div className="F-h p-2 pb-0">
                <p className="ms-3">Deposit USDT</p>
              </div>

              <div className="form-area d-flex justify-content-center align-items-center">
                <div className="card w-card  card deposit-card mg-lg-4 mt-2">


                  <div className="px-4">
                    <div className="row mt-1">
                      <div className="col-md-12 px-1">
                        <div className="text-center">
                          <div className="mb-0 mt-3 fle">
                            <p className="step-1 ">Wellet Status:</p>
                            <p className="inst mb-0 ms-3">
                              {connected == "Wallet is Connected" ? (
                                <h6
                                  className="para mt-2 text-center mb-2"
                                  style={{ color: "white" }}
                                >
                                  {connected}
                                </h6>
                              ) : (
                                <h6
                                  className="para mt-1 text-center"
                                  style={{ color: "red" }}
                                >
                                  {connected}
                                </h6>
                              )}
                            </p>
                          </div>
                          <div className="mb-0 fle">
                            <p className="step-1 ">USDT Balance:</p>
                            <p className="para inst mb-0 ms-3">{USDTBal}</p>
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            (Pay From)Wallet Address
                          </label>
                          <input
                            disabled
                            type="text"
                            className="form-control mt-2"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Enter Wallet Address"
                            value={walletAddress}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-md-12 px-1">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Amount (USDT)
                          </label>
                          <input
                            type="text"
                            className="form-control mt-2"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Enter  Amount"
                            value={USDAmount}
                            onChange={(e) => {
                              CalculateValueU2R(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-md-12 px-1">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Amount
                          </label>
                          <input
                            type="text"
                            className="form-control mt-2"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Amount (in Rupees)"
                            value={RupeeAmount}
                            onChange={(e) => {
                              CalculateValueR2U(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-md-12 px-1">
                        <div className="form-group">
                          <button
                            // type="submit"
                            className="btn deposit-f-btn mt-lg-4 mt-2  "
                            onClick={() => buyToken()}
                          >
                            Deposite Fund
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Deposit;
