import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Sliddeer from "./Sliddeer";
import Features from "./Features";
import About from "./About";
import Contact from "./Contact";
import Footerr from "./Footerr";
import { Carousel } from "antd/es";

export default function Hero() {
  return (
   
    <div>
      <Header />
      <div className="container-fluid py-lg-5 py-md-4 mb-5 mb-sm-4 mb-md-0 herosection website">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex align-items-lg-start justify-content-lg-center flex-column order-2 order-lg-1">
              {/* <div className="subHeading">Everything you need.</div> */}
              <div className="heading">
                Welcome 
                To <br /> <span className="text-theme1">Moscow Spin</span>
              </div>
              <p className="perograph py-2">
                Get ready for an exciting and vibrant experience as you dive
                into the world of colors. In this thrilling game, your task is
                to predict the next color that will appear. Trust your instincts
                and let the colors guide you to victory!
              </p>
              <div className="d-flex align-items-center justify-content-center justify-content-lg-start gap-2">
                <Link
                  to="/Login"
                  className="btn btn-theme1 px-5 py-3 buttonH1 w50">
                  Login
                </Link>
                <Link
                  to="/register"
                  className="btn btn-theme1 px-5 py-3 buttonH w50">
                  Register
                </Link>
              </div>
            </div>
            <div className="col-lg-6 text-end order-1 order-lg-2">
              <div className="heroimage">
                  <Carousel autoplay dots speed={300} className="banners pt-5">
                        <div className="">
                        <img src="./assets/image/home/1.jpg" class="d-block w-100 banners miss" alt="Slide 1"/>

                        </div>
                        <div className="">
                        <img src="./assets/image/home/2.jpg" class="d-block w-100 banners miss" alt="Slide 2"/>

                        </div>
                        <div className="">
                        <img src="./assets/image/home/3.jpg" class="d-block w-100 banners miss" alt="Slide 3"/>

                        </div>
                  </Carousel>
                {/* <img
                  className="w-100 h-100"
                  src="./assets/image/home/image1.png"
                  alt="imae"
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container-fluid py-lg-5 py-md-4 mb-5 mb-sm-4 mb-md-0 ourSrction">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-4">
              <div className="sectionHeading text-center">
                Our <span className="text-theme1">Trested</span> partner
              </div>
             
            </div>
            <div className="col-12 ">
              <Sliddeer />
            </div>
          </div>
        </div>
      </div> */}
      <Features />
      <About />
      <Contact />
      <Footerr />
    </div>
  );
}
