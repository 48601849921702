import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import Table from "../Table/Table";
import TableButtons from "../Pagination/TableButtons";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../API/Api";
import { UpdateAuth } from "../../Redux/AuthSlice";

const GameHistory = () => {
  const [showSidebar, setShowSidebar] = useState(false);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const handleLinkClick = () => {
    // Close the sidebar when a link is clicked
    setShowSidebar(false);
  };

  const [ReportApi, setReportApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(50);
  const dispatch = useDispatch();

  const { userId, jwt_token } = useSelector((state) => state.Auth);

  const Referral_API = async () => {
    try {
      let responce = await API.get(
        `/depositeFundHistory?uid=${userId}`,

        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      responce = responce.data.data[0];
      console.log("res referral-->", responce);
      setReportApi([]);

      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: index + 1,
          uid: item.uid,
          red: item.addreslist,
          green: item.addreslist,
          violet: item.addreslist,
          wallet: item.addreslist,
          number0: item.addreslist,
          number1: item.addreslist,
          number2: item.addreslist,
          number3: item.addreslist,
          number4: item.addreslist,
          number5: item.addreslist,
          number6: item.addreslist,
          number7: item.addreslist,
          number8: item.addreslist,
          number9: item.addreslist,
          numberResult: item.addreslist,
          colourResult: item.addreslist,
          requestedDate: item.dd,
        });
      });

      setReportApi(arr);
    } catch (e) {
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          UpdateAuth({
            isAuth: false,
            userId: null,
            jwt_token: null,
          })
        );
      }
    }
  };

  useEffect(() => {
    Referral_API();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = ReportApi.slice(indexOfFirstPage, indexOfLastPost);

  var [reportData, set_reportData] = useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "User Id", accessor: "uid" },
      { Header: "Wallet Address", accessor: "wallet" },
      { Header: "Green", accessor: "green" },
      { Header: "Violet", accessor: "violet" },
      { Header: "Red", accessor: "red" },
      { Header: "Number 0", accessor: "number0" },
      { Header: "Number 1", accessor: "number1" },
      { Header: "Number 2", accessor: "number2" },
      { Header: "Number 3", accessor: "number3" },
      { Header: "Number 4", accessor: "number4" },
      { Header: "Number 5", accessor: "number5" },
      { Header: "Number 6", accessor: "number6" },
      { Header: "Number 7", accessor: "number7" },
      { Header: "Number 8", accessor: "number8" },
      { Header: "Number 9", accessor: "number9" },
      { Header: "Result Number", accessor: "numberResult" },
      { Header: "Result Colour", accessor: "colourResult" },
      { Header: "Date", accessor: "requestedDate" },
    ],
  });

  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} showSidebar={showSidebar} />

      <Sidebar handleLinkClick={handleLinkClick} showSidebar={showSidebar} />
      <Footer />
      <div className="col-lg-10 com-h">
        <div className="row">
          <div className="col-12 p-3">
            <div className="bet-h ">
              <h4 className="p-2 ms-2">Game History</h4>
              <div className="container">
                <Table data={[...currentPost]} columns={reportData.cols} />
                <br />
                <TableButtons
                  indexOfFirstPage={indexOfFirstPage}
                  indexOfLastPost={indexOfLastPost}
                  setcurrentPage={setcurrentPage}
                  currentPage={currentPage}
                  totalData={ReportApi.length}
                  listPerpage={listPerpage}
                  handleRowsPerPageChange={setlistPerpage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GameHistory;
