import React from 'react'
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import club from '../../Media/logoclub99.png';

export default function Header() {
    const expand = "lg";
    return (
      <>
        <Navbar collapseOnSelect key={expand} expand={expand} className="bg-body-dark mb-4">
          <Container fluid>
            <Navbar.Brand href="/">
              <img
                className="w-100 h-100"
                src={club}
                alt="logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton></Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-start flex-grow-1 pe-3">
                  <Nav.Link href="/">Home</Nav.Link>
                  {/* <Nav.Link href="#feature">Feature</Nav.Link> */}
                  <Nav.Link href="#about">About</Nav.Link>
                  <Nav.Link href="#contact">Contact Us</Nav.Link>
                </Nav>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link className="btn-theme1" href="#action1">
                    <Link to="/Login" className="btn btn-theme1 buttonH1">
                      Login
                    </Link>
                  </Nav.Link>
                  <Nav.Link href="#action2">
                    <Link to="/register" className="btn btn-theme1 buttonH">
                      Register
                    </Link>
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </>
    );
}
