import React from "react";
import { useState, useEffect } from "react";
import "./Login.css";
import a from "../../Media/logoclub99.png";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { API } from "../../API/Api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { UpdateAuth } from "../../Redux/AuthSlice";

const Login = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [LoginId, setLoginId] = useState("");
  const [checkbox, setcheckbox] = useState(false);
  const [spinnerload, setspinnerload] = useState(false);
  const history = useNavigate();
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value.substring(0, 25) });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const schema = yup.object().shape({
    uid: yup.string().required("Id is required").max(8),
    password: yup.string().required("Password is required").max(25),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const CheckNumber = async (e) => {
    let { value } = e.target;

    const newValue = value.replace(/[^0-9]/gi, "").substring(0, 8);
    if (newValue === "") {
      setLoginId("");
    } else {
      setLoginId(newValue);
    }
  };

  const onSubmitHandler = async (data, e) => {
    e.preventDefault();
    setspinnerload(true);
    let res = await API.post("/login", {
      uid: data.uid,
      password: data.password,
    });

    console.log("res", res);

    if (res.data.data.result === "Successfull") {
      toast.success(`Login Successful`);
      dispatch(
        UpdateAuth({
          isAuth: res.data.success,
          userId: res.data.data.uid_output,
          jwt_token: res.data.token,
        })
      );
      history("/dashboard");
    } else {
      toast.error(`Invalid Login Id or Password`);
      setspinnerload(false);
    }

    setspinnerload(false);
  };

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <div className="background-img">
      <div className="d-flex justify-content-center align-items-center">
        <div className="card login-card1  mt-5">
          <div className="disp mt-4">
            <div className="pt-3">
          <h6 className="text-white">Create Account <a href="/Register">Sign Up</a></h6> 
           <h3 className="text-white">Login</h3>
           </div> 
           <a href="/"> <img className="img-fluid img-div" src={a} alt="a" style={{width:"100px"}}/></a>
          </div>

          <div className="px-4">
            <form onSubmit={handleSubmit(onSubmitHandler)}>
              <div className="mb-3">
                <TextField
                  fullWidth
                  type="text"
                  className="form-control"
                  id="outlined-required"
                  // label="Login Id"
                  placeholder="Login Id"
                  defaultValue=""
                  margin="normal"
                  {...register("uid", { required: true })}
                  value={LoginId}
                  maxLength={8}
                  onChange={(e) => {
                    CheckNumber(e);
                  }}
                  style={{"color":"black !important"}}
                />
                <p className="texterr">{errors.uid?.message}</p>

                {/* <label for="exampleInputEmail1" className="form-label">
                  User Name
                </label>
                <input
                  type="text"
                  className=" login-input p-1 px-2"
                  id="exampleInputEmail1"
                  placeholder="Enter UserName"
                  aria-describedby="emailHelp"
                  // onChange={(e) => {
                  //   setUserName(e.target.value);
                  // }}
                /> */}
              </div>
              <div className="mb-3">
                <TextField
                  fullWidth
                  id="outlined-password-input"
                  className="form-control"
                  // label="Password"
                  placeholder="Password"
                  autoComplete="current-password"
                  margin="normal"
                  type={values.showPassword ? "text" : "password"}
                  maxLength={25}
                  value={values.password}
                  {...register("password", { required: true })}
                  onChange={handleChange("password")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <p className="texterr">{errors.password?.message}</p>
                {/* <label for="exampleInputPassword1" className="form-label">
                  Password*
                </label>
                <input
                  type="password"
                  className=" login-input p-1 px-2"
                  placeholder="Enter your password"
                  id="exampleInputPassword1"
                  // onChange={(e) => {
                  //   setPassword(e.target.value);
                  // }}
                /> */}
              </div>
              <div className="">
                <p className="">
                  <Link className=" text-white " to="/forgetPassword">
                    Forgot Password?
                  </Link>
                </p>
              </div>

              <div className="d-flex justify-content-center mb-5">
                <button type="submit" className="btn login-btn mt-2">
                  {spinnerload ? (
                    <>
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </>
                  ) : (
                    "Login"
                  )}
                  {/* Login */}
                </button>
              </div>
              {/* <div className="bth d-flex justify-content-center mt-2">
                Back to{" "}
                <span>
                  <Link className="nav-link" to="/">
                    {" "}
                    Home
                  </Link>{" "}
                </span>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
