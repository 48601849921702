import React, { useEffect, useState } from "react";
import Defult from "./default.png";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import EditProfile from "./EditProfile";
import "./Profile.css";
import { API } from "../../API/Api";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAuth } from "../../Redux/AuthSlice";
import { useNavigate } from "react-router-dom";

export default function Profile() {
  const [showSidebar, setShowSidebar] = useState(false);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const handleLinkClick = () => {
    // Close the sidebar when a link is clicked
    setShowSidebar(false);
  };

  const { userId, jwt_token } = useSelector((state) => state.Auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [bankData, setbankData] = useState({});

  const UserInfo_API = async () => {
    try {
      const response = await API?.get(`getProfileDetails?uid=${userId}`, {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      });
      const userData = response.data.data[0][0];
      console.log("userinfo", userData);
      setUserData(userData);

      const bankres = await API?.get(`userBankDetails?uid=${userId}`, {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      });
      console.log("Response-->Bank", bankres);
      const BankData = bankres.data.data[0][0];
      setbankData(BankData);
    } catch (e) {
      console.log("Error While calling userinfomyReferrals API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  useEffect(() => {
    UserInfo_API();
  }, []);

  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} showSidebar={showSidebar} />
      <Sidebar handleLinkClick={handleLinkClick} showSidebar={showSidebar} />
      <div className="col-lg-10 home mt-2">
        <div className="col-12">
          <div className="Heading fw-bold px-2 py-sm-4 py-2">Profile</div>
        </div>
        {/* ***********Profile Section**************** */}
        <div className="">
          <div className="content">
            {/* <section className=" profileSetting"> */}
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-4 ">
                  <div className="card author-box Pbox">
                    <div className="card-body">
                      <div className="author-box-center text-center">
                        <img
                          src={Defult}
                          alt="img"
                          className="rounded-circle author-box-picture"
                        />
                        <div className="clearfix" />
                        <div
                          className="author-box-name text-center"
                          style={{ color: "#fff" }}
                        >
                          {userData.f_name}
                        </div>
                        <div className="author-box-job text-center text-white">Member</div>
                      </div>
                      <div className="text-center">
                        <div className="author-box-description"></div>
                        <div className="w-100 d-sm-none" />
                      </div>
                    </div>
                  </div>
                  <div className="card Pbox">
                    <div className="card-header">
                      <h4 style={{ color: "#fff" }}>
                        Personal Details
                      </h4>
                    </div>
                    <div className="card-body">
                      <div className="py-4">
                        {/* <div className="clearfix">
                              <span
                                className="float-left"
                                style={{ color: "rgb(194, 16, 16)" }}>
                                Birthday
                              </span>
                              <span className="float-right text-muted">
                                {userData.dob}
                              </span>
                            </div> */}
                        <div className="clearfix">
                          <span
                            className="float-left"
                            style={{ color: "#fff" }}
                          >
                            Phone
                          </span>
                          <span className="float-right text-muted text-white">
                            {userData.mobile}
                          </span>
                        </div>
                        <div className="clearfix">
                          <span
                            className="float-left"
                            style={{ color: "#fff" }}
                          >
                            Email
                          </span>
                          <span className="float-right text-muted text-white">
                            {userData.email}
                          </span>
                        </div>
                        <div className="clearfix">
                          <span
                            className="float-left"
                            style={{ color: "#fff" }}
                          >
                            UserId
                          </span>
                          <span className="float-right text-muted text-white">
                            {userData.uid}
                          </span>
                        </div>
                        <div className="clearfix">
                          <span
                            className="float-left"
                            style={{ color: "#fff" }}
                          >
                            Address
                          </span>
                          <span className="float-right text-muted text-white">
                            {userData.address}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-8">
                  <div className="card Pbox">
                    <div className="padding-20">
                      <EditProfile
                        userData={userData}
                        userBankData={bankData}
                        user={userId}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </section> */}
          </div>
        </div>
      </div>
    </>
  );
}
