import React from 'react'

export default function Footerr() {
    return (
        <div>
          <footer className="container-fluid pt-5">
            <div className="container border-bottom pb-3">
              <div className="row">
                
                <div className="col-md-4 text-center">
                  <div className="logo nscbsj" >
                    <img
                      className="w-100h h-100"
                      src="../assets/image/logo/logo.png"
                      alt="logo"style={{width:"100px"}}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <ul className="list-unstyled footerlist">
                    <li className='mb-2'>
                      <a href="/">Home</a>
                    </li>
                    
                    <li className='mb-2'>
                      <a href="#about">About</a>
                    </li>
                    <li>
                      <a href="#contact">Contact Us</a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <ul className="list-unstyled footerlist">
                    {/* <li>
                      <a href="">Connect wallet</a>
                    </li> */}
                    <li>
                      <a href="#contact">Contact Us:- Info@moscowspin.live</a>
                    </li>
                  </ul>
                  <ul className="socilaicon list-unstyled">
                    <li>
                      <a href="">
                        <span className="d-flex align-items-center justify-content-center">
                          <img
                            className="w-100 h-100"
                            src="../assets/image/home/facebook-f.png"
                            alt="facebook"
                          />
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <span className="d-flex align-items-center justify-content-center">
                          <img
                            className="w-100 h-100"
                            src="../assets/image/home/instagram.png"
                            alt="instagram"
                          />
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <span className="d-flex align-items-center justify-content-center">
                          <img
                            className="w-100 h-100"
                            src="../assets/image/home/telegram-plane.png"
                            alt="twitter"
                          />
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <span className="d-flex align-items-center justify-content-center">
                          <img
                            className="w-100 h-100"
                            src="../assets/image/home/twitter.png"
                            alt="telegram"
                          />
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className=" bottomtext text-center pt-2">
              <p className='mb-0 pb-3'>Copyright © Moscow Spin all rights reserved.</p>
            </div>
          </footer>
        </div>
      );
}
