import React, { useEffect, useState } from "react";
import "./withdrawal.css";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import { API } from "../../API/Api";
import { toast } from "react-toastify";
import { SetDashboardData, UpdateAuth } from "../../Redux/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import DOMPurify from "dompurify";
import { useNavigate } from "react-router-dom";

const WithDrawal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSidebar, setShowSidebar] = useState(false);
  const [wallettype, setWalletType] = useState("2");
  const [amount, setAmount] = useState("");
  const [amountusdt, setAmountUsdt] = useState("");
  const [otp, setOTP] = useState("");
  const [otpvalid, setOTPValid] = useState("0");
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const handleLinkClick = () => {
    // Close the sidebar when a link is clicked
    setShowSidebar(false);
  };
  const { userId, jwt_token } = useSelector((state) => state.Auth);
  const [Net_Balance, setNet_Balance] = useState("");
  const [Accountaddress, setAccountaddress] = useState("");
  // const { Net_Balance } = useSelector((state) => state.Auth.dashboardData);
  // const { Accountaddress } = useSelector((state) => state.Auth.dashboardData);

  const Dashboard_API = async () => {
    try {
      let res = await API.get(`userDashboard?uid=${userId}`, {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      });

      const responseData = res.data.data[0][0];
      setNet_Balance(responseData.Net_Balance);
      setAccountaddress(responseData.Accountaddress);
      
      dispatch(SetDashboardData(responseData));
    } catch (e) {
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };
  

  const setamountinusd = (e) => {
    setAmount(
      DOMPurify.sanitize(e.target.value.replace(/[^0-9]/g, "").substring(0, 5))
    );
    console.log("amountinr", e.target.value);
    setAmountUsdt((parseFloat(e.target.value) / 90).toFixed(6));
  };


  useEffect(() => {
    Dashboard_API();
  }, []);


  const SendOtp_API = async (e) => {
    e.preventDefault();
    try {
      if (Accountaddress === "") {
        toast.error("Update your Withdrawal Address !!!", {
          className: "copied-message",
        });

        return;
      }
      if (amount == "0" || amount == "" || amount == undefined) {
        toast.error("Please Enter Amount !!!", {
          className: "copied-message",
        });

        return;
      }
      // setOTPValid(1)
      let res = await API.post("SendOTPforWithdrawal_New", {
        uid: userId,
      },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        });
      res = res.data.data;
      console.log("SendOtp_API", res);
      if (res.result == "Correct Email ID !!") {
        setOTPValid(1);
        toast.success("OTP Send Successfully !!");
      } else {
        toast.error(res.result);
      }
      //   setOTPValid(1)
    } catch (e) {
      console.log("Somthing Error in SendOtp_API", e);
    }
  };

  const hendleOTP = (event) => {
    // let value = event.target.value;
    const newValue = event.replace(/[^0-9]/gi, "");
    setOTP(newValue);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      if (!wallettype) {
        toast.error("Select Wallet Type");
        return;
      }
      if (!amount || amount <= 0) {
        toast.error("Invalid Amount");
        return;
      }

      let responce = await API.post(
        `/Withdrawalnew`,
        {
          uid: userId,
          walletType: wallettype,
          amount: amount,
          otp: otp
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Submit API", responce);
      if (responce.data.data === "Withdrawal request has been sent.") {
        toast.success(responce.data.data);
        setOTPValid(0);
        setAmount("");
        setWalletType("");
        navigate("/dashboard");
      } else {

        toast.error(responce.data.data);
      }
    } catch (e) {
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          UpdateAuth({
            isAuth: false,
            userId: null,
            jwt_token: null,
          })
        );
      }
    }
  }
  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} showSidebar={showSidebar} />

      <Sidebar handleLinkClick={handleLinkClick} showSidebar={showSidebar} />
      <Footer />
      <div className="col-lg-10">
        <div className="row">
          <div className="col-12 px-2 ">
            <div className="withdrawal-main-div mt-1">
              <div className="F-h p-2 pb-0">
                <p className="ms-3">WithDrawal</p>
              </div>

              <div className="form-area d-flex justify-content-center align-items-center">
                <div className="d-flex justify-content-center flex-column">
                  {/* <div className="F-h p-2 pb-0">
                    <p className="ms-3">Upload USDT Amount</p>
                  </div> */}
                  <div className="card w-card mt-4">
                    <div className="mb-0">
                      <p className="b-o ms-4 mt-4">
                        Available Balance: {parseFloat(Net_Balance).toFixed(2)}{" "}
                      </p>
                    </div>

                    <div className="px-4">
                      <form>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control mt-2"
                            id="amount"
                            aria-describedby="amount"
                            placeholder="Enter  Amount In Point"
                            value={Accountaddress}

                          />

                          <select
                            id="type"
                            className="form-control mt-2 user-id-input"
                            value={wallettype}
                            onChange={(e) => setWalletType(e.target.value)}
                          >
                            {/* <option value="" disabled>
                              Select Wallet Type
                            </option> */}
                            {/* <option value="1">Bank (INR)</option> */}
                            <option value="2">Wallet (USDT)</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label htmlFor="amount">Amount (Point)</label>
                          <input
                            type="text"
                            className="form-control mt-2"
                            id="amount"
                            aria-describedby="amount"
                            placeholder="Enter  Amount In Point"
                            value={amount}
                            onChange={(e) => setamountinusd(e)}
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="amount">Amount (USDT)</label>
                          <input
                            type="text"
                            className="form-control mt-2"
                            id="amountusdt"
                            readOnly
                            aria-describedby="amountusdt"
                            placeholder="Amount In USDT"
                            value={amountusdt}
                          />
                        </div>
                        {otpvalid == "1" ? (
                          <>
                            <div className="form-group">
                              <label htmlFor="amount">OTP</label>
                              <input
                                type="text"
                                className="form-control mt-2"
                                id="amountusdt"
                                placeholder="OTP"
                                value={otp}
                                onChange={(e) => hendleOTP(e.target.value)}
                              />
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {otpvalid == "0" ? (
                          <button
                            className="btn transfer-btn mt-4"
                            onClick={(e) => SendOtp_API(e)}
                          >
                            Send Otp
                          </button>
                        ) : (
                          <button
                            className="btn transfer-btn mt-4"
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WithDrawal;
