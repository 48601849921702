import React from 'react'

export default function About() {
  return (
    <div id="about" className='mission'>
      {/* <div className="container-fluid treadingSection py-lg-5 py-md-4 mb-5 mb-sm-4 mb-md-0">
        <div className="row">
          <div className="col-md-6">
            <div className="mapimage">
              <img
                className="w-100 h-100"
                src="./assets/image/home/image 6.png"
                alt="imge"
              />
            </div>
          </div>
          <div className="col-md-6 text-start">
            <div className="topheading text-center">there online trading</div>
            <div className="sectionHeading">
              <span className="text-theme1">Betting Application</span>
              <br />
              For People.
            </div>
            <div className="subHeadinsection py-4">
              There are a lot of online Betting people accounts and people who
              are encouraging as according to them it has a lot of benefits In
              the near future
            </div>
            <button className="btn btn-theme1 px-5 py-3 rounded-3 buttonH">
              Create Now
            </button>
          </div>
        </div>
      </div> */}
      <div className="container-fluid cool_Section py-lg-5 py-md-4 mb-5 mb-sm-4 mb-md-0">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="topheading">there online trading</div>
              <div className="sectionHeading">
              <span className="text-theme1"> Moscow Spin</span> Mission
              </div>
              <div className="subHeadinsection">
                At the Moscow Spin, our mission is to create an immersive and
                entertaining gaming experience centered around the vibrant world
                of colors. We are driven by the belief that games should not
                only captivate but also inspire joy and creativity.
              </div>
              <div className="sectionHeading">
              <span className="text-theme1">  Moscow Spin </span>Vision
              </div>
              <div className="subHeadinsection">
                Our vision for the Color Prediction Game is to be a trailblazer
                in the gaming industry, redefining the way players interact with
                colors and fostering a global community united by their love for
                vibrant experiences.
              </div>
              {/* <button className="btn btn-theme1 px-5 py-3 rounded-3 buttonH">
                Create Now
              </button> */}
            </div>
            <div className="col-md-6">
               <div className="mapimage jkjsoqi pt-5">
                <center>
                {" "}
                <img
                  className="w-100 h-100 misss "
                  src="./assets/image/home/mission-vision.jpg"
                  alt="imag"
                />
                </center>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
