import React from 'react'
import SndSlider from './SndSlider';

export default function Contact() {
    return (
        <div id="contact">
          <div className="container-fluid marquesection py-md-4 mb-5 mb-sm-4 mb-md-0">
            <div className="row">
              <div className="col-12">
                <div className="marquee">
                  <div className="inner">
                    {/* <p className="d-flex align-items-center">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                      >
                        <span className="d-flex align-items-center justify-content-center">
                          <img
                            className="w-100 h-100"
                            src="./assets/image/home/icon1.png"
                            alt="image"
                          />
                        </span>
                        Cricket
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                      >
                        <span className="d-flex align-items-center justify-content-center">
                          <img
                            className="w-100 h-100"
                            src="./assets/image/home/icon2.png"
                            alt="image"
                          />
                        </span>
                        Football
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                      >
                        <span className="d-flex align-items-center justify-content-center">
                          <img
                            className="w-100 h-100"
                            src="./assets/image/home/icon3.png"
                            alt="image"
                          />
                        </span>
                        Tennis
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                      >
                        <span className="d-flex align-items-center justify-content-center">
                          <img
                            className="w-100 h-100"
                            src="./assets/image/home/icon4.png"
                            alt="image"
                          />
                        </span>
                        Bali-Ball
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                      >
                        <span className="d-flex align-items-center justify-content-center">
                          <img
                            className="w-100 h-100"
                            src="./assets/image/home/icon5.png"
                            alt="image"
                          />
                        </span>
                        Badminton{" "}
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                      >
                        <span className="d-flex align-items-center justify-content-center">
                          <img
                            className="w-100 h-100"
                            src="./assets/image/home/icon6.png"
                            alt="image"
                          />
                        </span>
                        Basketball{" "}
                      </a>
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-between"
                      >
                        <span className="d-flex align-items-center justify-content-center">
                          <img
                            className="w-100 h-100"
                            src="./assets/image/home/icon7.png"
                            alt="image"
                          />
                        </span>
                        Basketball{" "}
                      </a>
                    </p> */}
                    <SndSlider />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="container-fluid joinOurSection py-lg-5 py-md-4 mb-5 mb-sm-4 mb-md-0">
            <div className="row">
              <div className="col-md-6 mx-auto">
                <div className="text-center sectionHeading">
                  Join Our <span className="text-theme1"> Cryptocurrency</span> To
                  Make
                </div>
                <div className="subHeadinsection text-center">
                  There are a lot of online forex trading accounts and people who
                  are encouraging as according to them it has a lot of benefits In
                  the near future
                </div>
              </div>
            </div>
          </div> */}
        </div>
      );
}
