import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
// import SwiperCore, { Autoplay } from "swiper";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

export default function SndSlider() {
    const autoplayDelay = {
        320: 2000,
        480: 3000,
        768: 4000,
        1024: 5000,
      };
      return (
        
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            140: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
            1224: {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          }}
          modules={[Autoplay]}
          className="mySwiper"
        >
          <SwiperSlide>
            <a
              href="#"
              className="d-flex align-items-center justify-content-between"
            >
              <span className="d-flex align-items-center justify-content-center">
                <img
                  className="w-100 h-100"
                  src="./assets/image/home/icon1.png"
                  alt="imag"
                />
              </span>
              Cricket
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a
              href="#"
              className="d-flex align-items-center justify-content-between"
            >
              <span className="d-flex align-items-center justify-content-center">
                <img
                  className="w-100 h-100"
                  src="./assets/image/home/icon2.png"
                  alt="imag"
                />
              </span>
              Football
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a
              href="#"
              className="d-flex align-items-center justify-content-between"
            >
              <span className="d-flex align-items-center justify-content-center">
                <img
                  className="w-100 h-100"
                  src="./assets/image/home/icon3.png"
                  alt="imag"
                />
              </span>
              Tennis
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a
              href="#"
              className="d-flex align-items-center justify-content-between"
            >
              <span className="d-flex align-items-center justify-content-center">
                <img
                  className="w-100 h-100"
                  src="./assets/image/home/icon4.png"
                  alt="imag"
                />
              </span>
              Bali-Ball
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a
              href="#"
              className="d-flex align-items-center justify-content-between"
            >
              <span className="d-flex align-items-center justify-content-center">
                <img
                  className="w-100 h-100"
                  src="./assets/image/home/icon5.png"
                  alt="imag"
                />
              </span>
              Badminton{" "}
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a
              href="#"
              className="d-flex align-items-center justify-content-between"
            >
              <span className="d-flex align-items-center justify-content-center">
                <img
                  className="w-100 h-100"
                  src="./assets/image/home/icon6.png"
                  alt="imag"
                />
              </span>
              Basketball{" "}
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a
              href="#"
              className="d-flex align-items-center justify-content-between"
            >
              <span className="d-flex align-items-center justify-content-center">
                <img
                  className="w-100 h-100"
                  src="./assets/image/home/icon7.png"
                  alt="imag"
                />
              </span>
              Basketball{" "}
            </a>
          </SwiperSlide>
        </Swiper>
      );
}
