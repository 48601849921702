import React, { useState, useEffect } from "react";
import SliderH from "./SliderH";
import "./MatchesDetail.css";
// import { FaPlay } from "react-icons/fa";
// import cricket from "../../Media/5.png";
// import { BsFillPersonFill } from "react-icons/bs";
// import tennis from "../../Media/4.png";
// import football from "../../Media/6.png";
// import MatchData from "./MatchData";
// import InPlayMatchData from "./InPlayMatchData";
// import aircraft from "../../Media/output-onlinepngtools (5).png";
import colorimg from "../../Media/output-onlinepngtools (8).png";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TodayBusiness from "../../Media/profile/TodayBusiness.svg";
import network from "../../Media/profile/NetIncome.svg";
import icon from "../../Media/icon.png";
import icon1 from "../../Media/icon1.png";
import icon2 from "../../Media/icon2.png";
import icon3 from "../../Media/icon3.png";
import icon4 from "../../Media/icon4.png";
import icon5 from "../../Media/icon5.png";
import icon6 from "../../Media/icon6.png";
import icon7 from "../../Media/icon7.png";
import icon8 from "../../Media/icon8.png";
import icon9 from "../../Media/icon9.png";
import icon10 from "../../Media/icon10.png";
import icon11 from "../../Media/icon11.png";
import icon12 from "../../Media/icon12.png";
import icon13 from "../../Media/icon13.png";
import game from "../../Media/game.png";
const MatchesDetail = () => {
  const [active, setActive] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const dispatch = useDispatch();
  // const { data, loading, error } = useSelector((state) => state.api);
  // console.log(data ,"data of api");

  // useEffect(() => {
  //   dispatch(fetchData());
  // }, [dispatch]);

  useEffect(() => {
    // Toggle the visibility of the image every second
    const interval = setInterval(() => {
      setIsVisible((prevVisible) => !prevVisible);
    }, 500);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  const dashboardData = useSelector((state) => state.Auth.dashboardData);
  const {
    SignupBonus,
    BinaryBonus,
    BinaryLevelBonus,
    SalaryBonus,
    RoyaltyBonus,
    WinAmount,
    RceivedPlayingWallet,
    Net_DepositeFund,
    Used_DepositeFund,
    Total_DepositeFund,
    FirstDepositBonus,
    Total_Bonus_Wallet,
    TotalIncome,
    Net_Balance,
    Used_Balance,
    Total_5XIncome,
    Total_BettingA,
    Total_Caping,
    Total_Remain,
    WithDrawal_Amount,
    TodayBinaryBonus,
    TotalBinaryCaping,
  } = dashboardData || {};

  return (
    <div className=" col-lg-9 p-2 center-div">
      <SliderH />
      <div className="MainArea ">
        <div className="sport-div bg-dark2 row">
          {/* <div
            onClick={() => {
              setActive(true);
            }}
            className={`single-sport-div ${active && "is-active"}`}
          >
            <div className="row">
              <div className="col-10 d-flex flex-column justify-content-end align-items-center ">
                <div
                  className={`round-sport-div d-flex justify-content-center align-items-center ms-1 mt-2 ${
                    active && "is-activ"
                  }`}
                >
                  <img
                    className=" sport-img ms-1"
                    src={cricket}
                    alt="cricket"
                  />
                </div>
                <p className={`ms-1 sport-name mt-1 ${active && "is-act"}`}>
                  CRICKET
                </p>
              </div>
              <div className="col-2 ">
                <div className="num-div d-flex justify-content-center ">13</div>
              </div>
            </div>
          </div> */}
          {/* <div className="single-sport-div">
            <div className="row">
              <div className="col-10 d-flex flex-column justify-content-end align-items-center ">
                <div className=" round-sport-div d-flex justify-content-center align-items-center  mt-2">
                  <img className=" sport-img " src={football} alt="cricket" />
                </div>
                <p className="ms-1 sport-name mt-1">SOCCER</p>
              </div>
              <div className="col-2">
                <div className="num-div d-flex justify-content-center">13</div>
              </div>
            </div>
          </div> */}
          {/* <div className="single-sport-div">
            <div className="row">
              <div className="col-10 d-flex flex-column justify-content-center align-items-center ">
                <div className=" round-sport-div d-flex justify-content-center align-items-center ms-1 mt-2">
                  <img className=" sport-img ms-1" src={tennis} alt="cricket" />
                </div>
                <p className="ms-1 mt-1 sport-name">TENNIS</p>
              </div>
              <div className="col-2">
                <div className="num-div d-flex justify-content-center align-items-center">
                  13
                </div>
              </div>
            </div>
          </div> */}

          <div className="single-sport-div ">
            <div className="row">
              <div className="col-md-12">
                <NavLink to="/ColorGame" className="nav-link">
                  <center>
                  <img src={game} className="wid"></img>
                  </center>
                  {/* <div className="blink-img-div">
                    <img src="../../Media/game.png" className=""/>
                    {isVisible && (
                      <img
                        className="image-size img-fluid "
                        src={colorimg}
                        alt="a"
                      />
                    )}
                  </div>
                  <p className="ms-1  sport-name">COLOUR</p> */}
                </NavLink>
              </div>
            </div>
          </div>
          {/* <div className="single-sport-div ms-1">
            <NavLink to="/Aviator" className="nav-link">
              <div className="image-div">
                {isVisible && (
                  <img
                    className=" a-image-size img-fluid mt-2"
                    src={aircraft}
                    alt="a"
                  />
                )}
              </div>
              <p className="ms-1 mt-1 sport-name mt-3">AVIATOR</p>
            </NavLink>
          </div> */}

          {/* <----------------------MatchData-----------------------> */}
          {/* <MatchData />

          <InPlayMatchData />
          <MatchData />
          <MatchData />
          <MatchData /> */}
        </div>

        {/* ********************************* MLM BOX **************************** */}
        <br />
        <div className="row g-3 mx-0 my-3 samllcardsection">
          <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            <a href="/TotalDepositFund">
              <div className="card">
                <div className="card-body">
                  <div className="row mx-0">
                    <div className="col-3 px-0">
                      <div className="cardimage">
                        <img
                          className="w-75 h-75 imgcolor"
                          src={icon}
                          alt="TodayBusiness"
                        />
                      </div>
                    </div>
                    <div className="col-9 px-0">
                      <div className="card_heading">Total Deposit Fund</div>
                      <div className="sub_heading">{Total_DepositeFund}</div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            <a href="/allself">
              <div className="card">
                <div className="card-body">
                  <div className="row mx-0">
                    <div className="col-3 px-0">
                      <div className="cardimage">
                        <img
                          className="w-75 h-75 imgcolor"
                          src={icon}
                          alt="TodayBusiness"
                        />
                      </div>
                    </div>
                    <div className="col-9 px-0">
                      <div className="card_heading">First Deposit Bonus</div>
                      <div className="sub_heading">{FirstDepositBonus}</div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            <a href="/allreferral">
              <div className="card">
                <div className="card-body">
                  <div className="row mx-0">
                    <div className="col-3 px-0">
                      <div className="cardimage">
                        <img
                          className="w-75 h-75 imgcolor"
                          src={icon3}
                          alt="TodayBusiness"
                        />
                      </div>
                    </div>
                    <div className="col-9 px-0">
                      <div className="card_heading">SignUp Bonus</div>
                      <div className="sub_heading">{SignupBonus}</div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            {/* <a href="/StakingLevelBonus"> */}
            <div className="card">
              <div className="card-body">
                <div className="row mx-0">
                  <div className="col-3 px-0">
                    <div className="cardimage">
                      <img
                        className="w-75 h-75 imgcolor"
                        src={icon3}
                        alt="TodayBusiness"
                      />
                    </div>
                  </div>
                  <div className="col-9 px-0">
                    <div className="card_heading">Total Bonus Wallet</div>
                    <div className="sub_heading">{Total_Bonus_Wallet}</div>
                  </div>
                </div>
              </div>
            </div>
            {/* </a> */}
          </div>

          <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            <a href="/UsedDepositeFund">
              <div className="card">
                <div className="card-body">
                  <div className="row mx-0">
                    <div className="col-3 px-0">
                      <div className="cardimage">
                        <img
                          className="w-75 h-75 imgcolor"
                          src={icon1}
                          alt="TodayBusiness"
                        />
                      </div>
                    </div>
                    <div className="col-9 px-0">
                      <div className="card_heading">Used Bonus Wallet</div>
                      <div className="sub_heading">{Used_DepositeFund}</div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            {/* <a href="/stakingGlobalteam"> */}
            <div className="card">
              <div className="card-body">
                <div className="row mx-0">
                  <div className="col-3 px-0">
                    <div className="cardimage">
                      <img
                        className="w-75 h-75 imgcolor"
                        src={icon2}
                        alt="TodayBusiness"
                      />
                    </div>
                  </div>
                  <div className="col-9 px-0">
                    <div className="card_heading">Balance Wallet Bonus</div>
                    <div className="sub_heading">{Net_DepositeFund}</div>
                  </div>
                </div>
              </div>
            </div>
            {/* </a> */}
          </div>

          <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            <a href="/BinaryBonus">
              <div className="card">
                <div className="card-body">
                  <div className="row mx-0">
                    <div className="col-3 px-0">
                      <div className="cardimage">
                        <img
                          className="w-75 h-75 imgcolor"
                          src={icon4}
                          alt="TodayBusiness"
                        />
                      </div>
                    </div>
                    <div className="col-9 px-0">
                      <div className="card_heading">Binary Bonus</div>
                      <div className="sub_heading">{BinaryBonus}</div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>

          <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            <a href="/levelbonus">
              <div className="card">
                <div className="card-body">
                  <div className="row mx-0">
                    <div className="col-3 px-0">
                      <div className="cardimage">
                        <img
                          className="w-75 h-75 imgcolor"
                          src={icon5}
                          alt="TodayBusiness"
                        />
                      </div>
                    </div>
                    <div className="col-9 px-0">
                      <div className="card_heading">Sponser Binary Bonus</div>
                      <div className="sub_heading">{BinaryLevelBonus}</div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          {/* <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            <a href="/salarydaily">
              <div className="card">
                <div className="card-body">
                  <div className="row mx-0">
                    <div className="col-3 px-0">
                      <div className="cardimage">
                        <img
                          className="w-75 h-75 imgcolor"
                          src={icon6}
                          alt="TodayBusiness"
                        />
                      </div>
                    </div>
                    <div className="col-9 px-0">
                      <div className="card_heading"> Salary Bonus</div>
                      <div className="sub_heading">{SalaryBonus}</div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div> */}
          <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            <a href="/RoyaltyBonus">
              <div className="card">
                <div className="card-body">
                  <div className="row mx-0">
                    <div className="col-3 px-0">
                      <div className="cardimage">
                        <img
                          className="w-75 h-75 imgcolor"
                          src={icon7}
                          alt="TotalIncome"
                        />
                      </div>
                    </div>
                    <div className="col-9 px-0">
                      <div className="card_heading">Royalty Bonus</div>
                      <div className="sub_heading">{RoyaltyBonus}</div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            <a href="/WinningAmountReport">
              <div className="card">
                <div className="card-body">
                  <div className="row mx-0">
                    <div className="col-3 px-0">
                      <div className="cardimage">
                        <img
                          className="w-75 h-75 imgcolor"
                          src={icon7}
                          alt="TotalIncome"
                        />
                      </div>
                    </div>
                    <div className="col-9 px-0">
                      <div className="card_heading">Winning Amount</div>
                      <div className="sub_heading">{WinAmount}</div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            <a href="/FundReceivedHistory">
              <div className="card">
                <div className="card-body">
                  <div className="row mx-0">
                    <div className="col-3 px-0">
                      <div className="cardimage">
                        <img
                          className="w-75 h-75 imgcolor"
                          src={icon7}
                          alt="TotalIncome"
                        />
                      </div>
                    </div>
                    <div className="col-9 px-0">
                      <div className="card_heading">
                        Received Playing Wallet
                      </div>
                      <div className="sub_heading">{RceivedPlayingWallet}</div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>

          <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            {/* <a href="/AutopoolIncome"> */}
            <div className="card">
              <div className="card-body">
                <div className="row mx-0">
                  <div className="col-3 px-0">
                    <div className="cardimage">
                      <img
                        className="w-75 h-75 imgcolor"
                        src={TodayBusiness}
                        alt="TodayBusiness"
                      />
                    </div>
                  </div>
                  <div className="col-9 px-0">
                    <div className="card_heading">Total Playing Wallet</div>
                    <div className="sub_heading">{TotalIncome}</div>
                  </div>
                </div>
              </div>
            </div>
            {/* </a> */}
          </div>
          <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            <a href="/UsedPlayingWallet">
              <div className="card">
                <div className="card-body">
                  <div className="row mx-0">
                    <div className="col-3 px-0">
                      <div className="cardimage">
                        <img
                          className="w-75 h-75 imgcolor"
                          src={icon11}
                          alt="TodayBusiness"
                        />
                      </div>
                    </div>
                    <div className="col-9 px-0">
                      <div className="card_heading">Used Playing Wallet</div>
                      <div className="sub_heading">{Used_Balance}</div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            {/* <a href="/AutopoolIncome"> */}
            <div className="card">
              <div className="card-body">
                <div className="row mx-0">
                  <div className="col-3 px-0">
                    <div className="cardimage">
                      <img
                        className="w-75 h-75 imgcolor"
                        src={icon12}
                        alt="TodayBusiness"
                      />
                    </div>
                  </div>
                  <div className="col-9 px-0">
                    <div className="card_heading">Balance Playing Wallet</div>
                    <div className="sub_heading">{Net_Balance}</div>
                  </div>
                </div>
              </div>
            </div>
            {/* </a> */}
          </div>
          <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            <a href="/BinaryBonus">
              <div className="card">
                <div className="card-body">
                  <div className="row mx-0">
                    <div className="col-3 px-0">
                      <div className="cardimage">
                        <img
                          className="w-75 h-75 imgcolor"
                          src={icon13}
                          alt="TodayBusiness"
                        />
                      </div>
                    </div>
                    <div className="col-9 px-0">
                      <div className="card_heading">Today Binary</div>
                      <div className="sub_heading">{TodayBinaryBonus}</div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            {/* <a href="/AutopoolIncome"> */}
            <div className="card">
              <div className="card-body">
                <div className="row mx-0">
                  <div className="col-3 px-0">
                    <div className="cardimage">
                      <img
                        className="w-75 h-75 imgcolor"
                        src={icon13}
                        alt="TodayBusiness"
                      />
                    </div>
                  </div>
                  <div className="col-9 px-0">
                    <div className="card_heading">Today Binary Caping</div>
                    <div className="sub_heading">{TotalBinaryCaping}</div>
                  </div>
                </div>
              </div>
            </div>
            {/* </a> */}
          </div>
        </div>

        {/* ***************** MLM BOX END*************** */}
      </div>
    </div>
  );
};

export default MatchesDetail;
