import React, { useEffect, useState } from "react";
import "./RightSidebar.css";
import Maincard from "./Maincard";
import SmallCards from "./SmallCards";
import profile from "../../Media/profile/profile.png";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";

const RightSidebar = () => {
  const dashboardData = useSelector((state) => state.Auth.dashboardData);
  const {
    userid,
    TotalTeam,
    TotalReferral,
    TodayRightTeam,
    TodayLeftTeam,
    totalTeamBusiness,
    totalDirectBusiness,
    TotalLeftTeam,
    TotalRightTeam,
    TotalLeftReferral,
    TotalRightReferral,
    matchingBusiness,
  } = dashboardData || {};

  let URL = window.location.origin;

  function handleCopy() {
    let inputText = `${URL}/register?userid=${userid}`;

    navigator.clipboard.writeText(inputText);
    toast.success("Copied");
  }

  const [ipAddress, setIP] = useState("");
  useEffect(() => {
    async function IPAddress_API() {
      try {
        let response = await axios.get("https://geolocation-db.com/json/");
        console.log("response Geolocation", response.data.IPv4);
        setIP(response.data.IPv4);
      } catch (e) {
        console.log("Error in Ip Api", e);
      }
    }

    IPAddress_API();
  }, []);
  return (
    <div className="col-lg-3 L-sidebar d-lg-block">
      <div className="left-innerbar">
        {/* <Maincard /> */}
        <div className="row">
          <div className="card border-0 topcard profilcard h-100">
            <div
              className="card-body"
              style={{
                display: "flex",
                flexDirection: "column",
                border: "2px solid rgb(19 148 13)",
                justifyContent: "center",
                
                alignItems: "center",
                background: "rgb(8 15 37)",
                borderRadius:"15px",
                marginTop:"10px"
              }}
            >
              <div className="card_profileimg mb-3">
                <img
                  className="rounded-pill"
                  src={profile}
                  alt="profile"
                  style={{width:"100px"}}
                />
              </div>
              <div
                className="userid"
                style={{
                  color: "white",
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "700",
                }}
              >
                User Id:
              </div>
              <div
                className=" my-2"
                style={{
                  color: "white",
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                {userid}
              </div>

              <div
                className="wallet "
                style={{
                  color: "white",
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "700",
                }}
              >
                IP Address:
              </div>
              <p
                className="text-center copytext border-theme1"
                id="walletAddress"
                style={{
                  color: "white",
                  textAlign: "center",
                  fontSize: "17px",
                  fontWeight: "500",
                  // border: "1px solid #005fcb",
                  padding: "6px",
                  marginTop: "10px",
                }}
              >
                {ipAddress}
              </p>

              <div
                className="wallet "
                style={{
                  color: "white",
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "700",
                }}
              >
                Affiliate Link
              </div>
              <p
                className="text-center copytext border-theme1"
                id="walletAddress"
                style={{
                  color: "white",
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "500",
                  border: "1px solid #005fcb",
                  padding: "6px",
                  marginTop: "10px",
                }}
                onClick={handleCopy}
              >
                Copy Referral Link
              </p>
            </div>
          </div>

          {/* <SmallCards /> */}
        </div>

        <div
          className="card bg-transparent topcard"
          style={{
            justifyContent: "center",
          }}
        >
          <div className="card-body">
            <div className="card-heading mb-2 text-white fw-bold meddle">Team</div>
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div className="d-flex align-items-center gap-2 text-white">
                Matching Business
              </div>
              <div className="active text-white">{matchingBusiness}</div>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div className="d-flex align-items-center gap-2 text-white">
                Total Team
              </div>
              <div className="in_active text-white">{TotalTeam}</div>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div className="d-flex align-items-center gap-2 text-white">
                Total Left Team
              </div>
              <div className="in_active text-white">{TotalLeftTeam}</div>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div className="d-flex align-items-center gap-2 text-white">
                Total Right Team
              </div>
              <div className="in_active text-white">{TotalRightTeam}</div>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div className="d-flex align-items-center gap-2 text-white">
                Today Left Team
              </div>
              <div className="in_active text-white">{TodayLeftTeam}</div>
            </div>

            <div className="d-flex align-items-center justify-content-between mb-2">
              <div className="d-flex align-items-center gap-2 text-white">
                Today Right Team
              </div>
              <div className="in_active text-white">{TodayRightTeam}</div>
            </div>
          </div>
        </div>

        <div
          className="card bg-transparent topcard"
          style={{
            marginTop: "20px",
            border: "1px solid rgb(19 148 13)",
            justifyContent: "center",
            background: "rgb(51, 112, 27) !important",
          }}
        >
          <div className="card-body">
            <div className="card-heading mb-2 text-white fw-bold meddle">Referral</div>
            {/* <div className="d-flex align-items-center justify-content-between mb-2">
              <div className="d-flex align-items-center gap-2 text-white">
                Referral Business
              </div>
              <div className="active text-white">{totalDirectBusiness}</div>
            </div> */}
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div className="d-flex align-items-center gap-2 text-white">
                Total Referral
              </div>
              <div className="in_active text-white">{TotalReferral}</div>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div className="d-flex align-items-center gap-2 text-white">
                Left Referral
              </div>
              <div className="in_active text-white">{TotalLeftReferral}</div>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div className="d-flex align-items-center gap-2 text-white">
                Right Referral
              </div>
              <div className="in_active text-white">{TotalRightReferral}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightSidebar;
