import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import Table from "../Table/Table";
import TableButtons from "../Pagination/TableButtons";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../API/Api";
import { UpdateAuth } from "../../Redux/AuthSlice";

const WinningAmountReport = () => {
  const [showSidebar, setShowSidebar] = useState(false);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const handleLinkClick = () => {
    // Close the sidebar when a link is clicked
    setShowSidebar(false);
  };

  const [ReportApi, setReportApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(50);
  const dispatch = useDispatch();

  const { userId, jwt_token } = useSelector((state) => state.Auth);

  const Referral_API = async () => {
    try {
      let responce = await API.post(
        `/winningAmountReport`,
        {
          uid: userId,
        },

        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      responce = responce.data.data[0];
      console.log("res referral-->", responce);
      setReportApi([]);

      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: index + 1,
          uid: item.uid,
          date: item.dd,
          amount: item.odds,
          remark: item.remark,
        });
      });

      setReportApi(arr);
    } catch (e) {
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          UpdateAuth({
            isAuth: false,
            userId: null,
            jwt_token: null,
          })
        );
      }
    }
  };

  useEffect(() => {
    Referral_API();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = ReportApi.slice(indexOfFirstPage, indexOfLastPost);
  // sr: index + 1,
  // uid: item.uid,
  // date: item.date,
  // request_amount: item.request_amount,
  // adminchargerate: item.adminchargerate,
  // admincharge: item.admincharge,
  // netwithdrawal: parseFloat(item.request_amount) - parseFloat(item.admincharge),
  // withdrawType: item.withdrawType,
  // statuss: item.statuss,
  var [reportData, set_reportData] = useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: " User Id", accessor: "uid" },
      { Header: "Winning Amount", accessor: "amount" },
      //{ Header: "Remark", accessor: "remark" },
      { Header: "Date", accessor: "date" },
    ],
  });

  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} showSidebar={showSidebar} />

      <Sidebar handleLinkClick={handleLinkClick} showSidebar={showSidebar} />
      <Footer />
      <div className="col-lg-10 com-h">
        <div className="row">
          <div className="col-12 p-3">
            <div className="bet-h ">
              <h4 className="p-2 ms-2">Winning Amount Report</h4>
              {/* <div className="dates-div p-2 d-flex ">
                <div className="row">
                  <div className="col-md-5 mt-1">
                    <div className="d-flex">
                      <div className="from d-flex align-items-center justify-content-center">
                        From:
                      </div>
                      <div className="">
                        <input
                          name="start_date"
                          id="from"
                          type="date"
                          autocomplete="off"
                          placeholder="Select date from"
                          className="date-input p-2 "
                          value="2024-02-10"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 mt-1">
                    <div className="d-flex ">
                      <div className="from d-flex align-items-center justify-content-center">
                        To:
                      </div>
                      <div className="">
                        <input
                          name="start_date"
                          id="from"
                          type="date"
                          autocomplete="off"
                          placeholder="Select date from"
                          className="date-input p-2 "
                          value="2024-02-10"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2  mt-1">
                    <button className="btn btn-success ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-search"
                        viewBox="0 0 16 16">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                      </svg>{" "}
                      Search
                    </button>
                  </div>
                </div>
              </div> */}
              <div className="container">
                <Table data={[...currentPost]} columns={reportData.cols} />
                <br />
                <TableButtons
                  indexOfFirstPage={indexOfFirstPage}
                  indexOfLastPost={indexOfLastPost}
                  setcurrentPage={setcurrentPage}
                  currentPage={currentPage}
                  totalData={ReportApi.length}
                  listPerpage={listPerpage}
                  handleRowsPerPageChange={setlistPerpage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WinningAmountReport;
