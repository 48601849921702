import React from "react";
import { useState } from "react";
import home from "../../Media/1.png";
import inplay from "../../Media/2.png";
import casino from "../../Media/3.png";
import tennis from "../../Media/4.png";
import cricket from "../../Media/5.png";
import football from "../../Media/6.png";
import { IoMdArrowDropdown } from "react-icons/io";
import "./Sidebar.css";
import { Link, NavLink } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { VscSignOut } from "react-icons/vsc";
import { AiOutlineTeam } from "react-icons/ai";
import { TbReportAnalytics } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { UpdateAuth } from "../../Redux/AuthSlice";

function Sidebar({ showSidebar, handleLinkClick }) {
  const [inPlayMenu, setInplayMenu] = useState(false);
  const [tennisMenu, setTennisMenu] = useState(false);
  const [subMenu, setSubMenu] = useState(false);
  const [cricketMenu, setCricketMenu] = useState(false);
  const [footballMenu, setFootballMenu] = useState(false);

  var dropdown = document.getElementsByClassName("dropdown-btn");
  var i;

  for (i = 0; i < dropdown.length; i++) {
    dropdown[i].addEventListener("click", function () {
      this.classList.toggle("active");
      var dropdownContent = this.nextElementSibling;
      if (dropdownContent.style.display === "block") {
        dropdownContent.style.display = "none";
      } else {
        dropdownContent.style.display = "block";
      }
    });
  }

  const dispatch = useDispatch();
  const LogoutHandler = (e) => {
    dispatch(UpdateAuth({ isAuth: false, userId: "" }));
  };

  return (
    <div
      className={`sidebar   side-bar col-lg-2 col-sm-4  h-sm-auto d-lg-block${
        showSidebar ? "d-block" : " d-none"
      }`}
    >
      <div className="row">
        <div className="col-md-12">
          <div className="widthss">
          {/* <div className="sb-h py-2 pt-3 ">QUICK LINKS</div>
          <ul className="list-group">
            <li className="list-group-item">
              <div className="d-flex ">
                <NavLink
                  onClick={handleLinkClick}
                  className="nav-link"
                  to="/dashboard"
                >
                  <img className=" side-icon" src={home} alt="h" />
                  <span className="ms-2">HOME</span>
                </NavLink>
              </div>
            </li>
            <li className="list-group-item">
              <div className="d-flex ">
                <NavLink
                  onClick={handleLinkClick}
                  className="nav-link"
                  to="/FundRequestINR"
                >
                  <img className=" side-icon" src={home} alt="h" />
                  <span className="ms-2">FUND Request (INR)</span>
                </NavLink>
              </div>
            </li>
            {/* <li className="list-group-item">
              <div className="d-flex ">
                <NavLink
                  onClick={handleLinkClick}
                  className="nav-link"
                  to="/FundRequestUSDT"
                >
                  <img className=" side-icon" src={home} alt="h" />
                  <span className="ms-2">FUND Request (USDT)</span>
                </NavLink>
              </div>
            </li> 
            <li className="list-group-item">
              <div className="d-flex ">
                <NavLink
                  onClick={handleLinkClick}
                  className="nav-link"
                  to="/Withdrawal"
                >
                  <img className=" side-icon" src={home} alt="h" />
                  <span className="ms-2">Withdrawal </span>
                </NavLink>
              </div>
            </li>
            <li className="list-group-item">
              <div className="d-flex ">
                <NavLink
                  onClick={handleLinkClick}
                  className="nav-link"
                  to="/Deposit"
                >
                  <img className=" side-icon" src={home} alt="h" />
                  <span className="ms-2">DEPOSIT</span>
                </NavLink>
              </div>
            </li>
            <li className="list-group-item">
              <div className="d-flex ">
                <NavLink
                  onClick={handleLinkClick}
                  className="nav-link"
                  to="/WithdrawalHistory"
                >
                  <img className=" side-icon" src={home} alt="h" />
                  <span className="ms-2">Withdrawal HISTORY</span>
                </NavLink>
              </div>
            </li>
            <li className="list-group-item">
              <div className="d-flex ">
                <NavLink
                  onClick={handleLinkClick}
                  className="nav-link"
                  to="/TransactionHistory"
                >
                  <img className=" side-icon" src={home} alt="h" />
                  <span className="ms-2">TRANS HISTORY</span>
                </NavLink>
              </div>
            </li>
            {/* <li className="list-group-item">
              <div className="d-flex">
                <NavLink
                  onClick={handleLinkClick}
                  className="nav-link"
                  to="/InPlay">
                  <img className=" side-icon" src={inplay} alt="h" />
                  <span className="ms-2">IN-PLAY</span>
                </NavLink>
              </div>
            </li>
            <li className="list-group-item">
              <div className="d-flex">
                <NavLink
                  onClick={handleLinkClick}
                  className="nav-link"
                  to="/LiveCasino">
                  <img className=" side-icon" src={casino} alt="h" />
                  <span className="ms-2">LIVE CASINO</span>
                </NavLink>
              </div>
            </li> 
          </ul> */}
          {/* <div className="sb-h py-2 pt-3 "> </div> */}
          <ul className="list-group mt-3">
            <li className="list-group-item pt-1 mb-0 borderli">
              <div className="d-flex ">
                <NavLink
                  // onClick={handleLinkClick}
                  className="nav-link "
                  to="/dashboard"
                >
                  <i class="fa fa-home" aria-hidden="true"></i>
                  {/* <img className=" side-icon" src={home} alt="h" /> */}
                  {/* <VscSignOut /> */}
                  <span className="ms-2">Home</span>
                </NavLink>
              </div>
            </li>
          </ul>
          <ul className="list-group">
            <li>
              <Accordion >
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="list-group-item borderli">
                    {/* <img src={team} alt="" /> */}
                    {/* <AiOutlineTeam /> */}
                    <i class="fa fa-users" aria-hidden="true"></i>
                    <span className="ms-2">Team</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    {/* <ul> */}
                    <Link to="/TreeView">
                      <li className="list-group-item">Tree View</li>
                    </Link>
                    <Link to="/referrals">
                      <li className="list-group-item">My Referrals</li>
                    </Link>
                    <Link to="/leveldetail">
                      <li className="list-group-item">Level Details</li>
                    </Link>
                    <Link to="/downline">
                      <li className="list-group-item">My Downline</li>
                    </Link>
                    <Link to="/DLBusiness">
                      <li className="list-group-item">Direct Leg Business</li>
                    </Link>
                    {/* <Link to="/Reward_Team">
                        <li>Reward Team</li>
                      </Link> */}
                    {/* </ul> */}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="list-group-item borderli">
                    {/* <img src={report} alt="" /> */}
                    {/* <TbReportAnalytics /> */}
                    <i class="fa fa-briefcase" aria-hidden="true"></i>
                    <span className="ms-2">Bonus</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    {/* <ul> */}
                    {/* <Link to="/referraldeposite">
                      <li className="list-group-item">Referral Bonus </li>
                    </Link> */}
                    {/* <Link to="/selfdeposite">
                        <li className="list-group-item">First Deposite Self </li>
                      </Link> */}
                    <Link to="/allreferral">
                      <li className="list-group-item">SignUp Bonus</li>
                    </Link>
                    <Link to="/allself">
                      <li className="list-group-item">Deposit Bonus </li>
                    </Link>
                    <Link to="/BinaryBonus">
                      <li className="list-group-item">Binary Bonus</li>
                    </Link>
                    <Link to="/levelbonus">
                      <li className="list-group-item">Sponser Binary Bonus</li>
                    </Link>
                    <Link to="/salarydaily">
                      <li className="list-group-item">Salary Bonus</li>
                    </Link>
                    <Link to="/RoyaltyBonus">
                      <li className="list-group-item">Royalty Bonus</li>
                    </Link>
                    {/* <Link to="/weeklybonus">
                      <li className="list-group-item">Weekly bonus </li>
                    </Link> */}
                    {/* </ul> */}
                  </Accordion.Body>
                </Accordion.Item>

                {/* <Accordion.Item eventKey="2">
                  <Accordion.Header className="list-group-item">
                    <i class="fa fa-futbol-o" aria-hidden="true"></i>
                    <span className="ms-2">Funds Management</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Link to="/FundRequestINR">
                      <li className="list-group-item">Fund Request (BANK)</li>
                    </Link>
                    <Link to="/RequestHistory">
                      <li className="list-group-item">
                        Request History (BANK)
                      </li>
                    </Link>
                    <Link to="/FundRequestUPI">
                      <li className="list-group-item">Fund Request (UPI)</li>
                    </Link>
                    <Link to="/RequestHistoryUPI">
                      <li className="list-group-item">Request History (UPI)</li>
                    </Link>
                    <Link to="/FundReceivedHistory">
                      <li className="list-group-item">Received History </li>
                    </Link>
                  </Accordion.Body>
                </Accordion.Item> */}
                <Accordion.Item eventKey="3">
                  <Accordion.Header className="list-group-item borderli">
                    {/* <TbReportAnalytics /> */}
                    <i class="fa fa-user-secret" aria-hidden="true"></i>
                    <span className="ms-2">Withdrawal</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Link to="/Withdrawal">
                      <li className="list-group-item ">Withdrawal</li>
                    </Link>
                    <Link to="/WithdrawalHistory">
                      <li className="list-group-item">Withdrawal History </li>
                    </Link>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header className="list-group-item borderli">
                    {/* <TbReportAnalytics /> */}
                    <i class="fa fa-suitcase" aria-hidden="true"></i>
                    <span className="ms-2">Deposit Fund</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Link to="/Deposit">
                      <li className="list-group-item">Deposit Fund</li>
                    </Link>
                    <Link to="/DepositHistory">
                      <li className="list-group-item">Deposit Fund History </li>
                    </Link>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="40">
                  <Accordion.Header className="list-group-item borderli">
                    {/* <TbReportAnalytics /> */}
                    <i class="fa fa-suitcase" aria-hidden="true"></i>
                    <span className="ms-2">Fund Transfer</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Link to="/FundsTransfer">
                      <li className="list-group-item">Fund Transfer</li>
                    </Link>
                    <Link to="/FundTransferHistory">
                      <li className="list-group-item">Fund Transfer History</li>
                    </Link>
                    <Link to="/FundReceivedHistory">
                      <li className="list-group-item">Fund Received History</li>
                    </Link>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </li>
            {/* <li className="list-group-item">
              <NavLink className="nav-link">
              <div
                onClick={() => {
                  setInplayMenu(!inPlayMenu);
                }}
                className="d-flex"
              >
                <img className=" side-icon" src={inplay} alt="h" />
                <span className="ms-2">IN-PLAY</span>
              </div>

              </NavLink>
              
            </li>
            {inPlayMenu && (
              <div className="menu d-flex justify-content-between align-items-center px-2 ">
                Test Match
                
              </div>
            )}
            <li className="list-group-item">
              <NavLink className="nav-link">
              <div
                onClick={() => {
                  setCricketMenu(!cricketMenu);
                }}
                className="d-flex"
              >
                <img className=" side-icon" src={cricket} alt="h" />
                <span className="ms-2">CRICKET</span>
              </div>

              </NavLink>
              
            </li>
            {cricketMenu && (
              <div className="d-flex flex-column">
                <div
                  onClick={() => setSubMenu(!subMenu)}
                  className="menu d-flex justify-content-between align-items-center px-2 "
                >
                  Test Match
                  <IoMdArrowDropdown className="menu-icon" />
                </div>
                {subMenu && (
                  <div className="d-flex flex-column">
                    <div className="subMenu d-flex justify-content-center align-items-center">
                      <NavLink onClick={handleLinkClick} to="/Sport" className="nav-link">
                        Pakistan vs Newzeeland
                      </NavLink>
                    </div>
                    <div className="subMenu d-flex justify-content-center align-items-center">
                      <NavLink onClick={handleLinkClick}  to="/Sport" className="nav-link">
                        Pakistan vs Newzeeland
                      </NavLink>
                    </div>
                  </div>
                )}
              </div>
            )}

            <li className="list-group-item">
              <NavLink className="nav-link">
              <div
                onClick={() => {
                  setTennisMenu(!tennisMenu);
                }}
                className="d-flex"
              >
                <img className=" side-icon" src={tennis} alt="h" />
                <span className="ms-2">TENNIS</span>
              </div>

              </NavLink>
              
            </li>
            {tennisMenu && (
              <div className="d-flex flex-column">
                <div
                  onClick={() => setSubMenu(!subMenu)}
                  className="menu d-flex justify-content-between align-items-center px-2 "
                >
                  Test Match
                  <IoMdArrowDropdown className="menu-icon" />
                </div>
                {subMenu && (
                  <div className="d-flex flex-column">
                    <div className="subMenu d-flex justify-content-center align-items-center">
                      <NavLink onClick={handleLinkClick}  to="/Sport" className="nav-link">
                        Pakistan vs Newzeeland
                      </NavLink>
                    </div>
                    <div className="subMenu d-flex justify-content-center align-items-center">
                      <NavLink onClick={handleLinkClick}  to="/Sport" className="nav-link">
                        Pakistan vs Newzeeland
                      </NavLink>
                    </div>
                  </div>
                )}
              </div>
            )}

            <li className="list-group-item">
              <NavLink className="nav-link">
              <div
                onClick={() => {
                  setFootballMenu(!footballMenu);
                }}
                className="d-flex"
              >
                <img className=" side-icon" src={football} alt="h" />
                <span className="ms-2">FOOTBALL</span>
              </div>
              </NavLink>
            </li>
            {footballMenu && (
              <div className="d-flex flex-column">
                <div
                  onClick={() => setSubMenu(!subMenu)}
                  className="menu d-flex justify-content-between align-items-center px-2 "
                >
                  Test Match
                  <IoMdArrowDropdown className="menu-icon" />
                </div>
                {subMenu && (
                  <div className="d-flex flex-column">
                    <div className="subMenu d-flex justify-content-center align-items-center">
                      <NavLink onClick={handleLinkClick}  to="/Sport" className="nav-link">
                        Pakistan vs Newzeeland
                      </NavLink>
                    </div>
                    <div className="subMenu d-flex justify-content-center align-items-center">
                      <NavLink onClick={handleLinkClick}  to="/Sport" className="nav-link">
                        Pakistan vs Newzeeland
                      </NavLink>
                    </div>
                  </div>
                )}
              </div>
            )} */}
          </ul>
          {/* <ul className="list-group">
            <li className="list-group-item">
              <div className="d-flex ">
                <NavLink
                  // onClick={handleLinkClick}
                  className="nav-link"
                  to="/GameHistory"
                >
                  
                  <VscSignOut />
                  <span className="ms-2">Game History</span>
                </NavLink>
              </div>
            </li>
          </ul> */}
          <ul className="list-group">
          <li className="list-group-item pt-1 borderli mt-0">
              <div className="d-flex ">
              
                <a
                  // onClick={handleLinkClick}
                  className="nav-link"
                  href="moscowspin.pdf"
                 target="_blank"
                >
                  {/* <img className=" side-icon" src={home} alt="h" /> */}
                  {/* <VscSignOut /> */}
                    <i class="fa fa-file-text" aria-hidden="true"></i>
                  <span className="ms-2"> Plan Pdf</span>
                </a>
              </div>
            </li>
            <li className="list-group-item pt-1 borderli mt-0">
              <div className="d-flex ">
              
              <NavLink
                  // onClick={handleLinkClick}
                  className="nav-link"
                  to="/Support"
                >
                  {/* <img className=" side-icon" src={home} alt="h" /> */}
                  {/* <VscSignOut /> */}
                    <i class="fa fa-file-text" aria-hidden="true"></i>
                  <span className="ms-2">Support</span>
                  </NavLink>
              </div>
            </li>
            <li className="list-group-item pt-1 borderli mt-0">
              <div className="d-flex ">
              
                <NavLink
                  // onClick={handleLinkClick}
                  className="nav-link"
                  to="/"
                  onClick={LogoutHandler}
                >
                  {/* <img className=" side-icon" src={home} alt="h" /> */}
                  {/* <VscSignOut /> */}
                  <i class="fa fa-sign-out" aria-hidden="true"></i>
                  <span className="ms-2">Sign Out</span>
                </NavLink>
              </div>
            </li>
          </ul>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
